@value (
  primary-color, on-primary-color,
  secondary-color, on-secondary-color
) from "../colors.module.css";

.footer {
  font-family: var(--theme-widget-font-family);
  composes: typography-footer from global;

  background-color: primary-color;
  color: on-primary-color;
  padding: space(8) 8%;
  position: relative;
  z-index: 10;
}

.footer a {
  color: inherit;
  text-decoration: none;
}

.footer ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.logo {
  display: block;
  margin: 0 auto space(8);
  width: 100px;
}

.columns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  max-width: 1000px;
  margin: 0 auto;
}

.column {
  max-width: 150px;
  width: 100%;
  margin-bottom: space(8);
}

.column h2 {
  font-size: 1em;
  margin: 0 0 space(2);
}

.copy {
  text-align: center;
}
